<template>
    <v-container>
        <AdBTWR v-if="!user.subscribed"/>

        <v-row justify="center" class="mt-10">
            <v-col cols="12" md="6">
                <v-card v-if="resetToken" class="elevation-10" :xcolor="passwordResetAction.color">
                    <v-card-title class="headline">Reset Password</v-card-title>
                    <v-card-text v-if="passwordResetAction.state">
                        <p>{{ passwordResetAction.message }}</p>

                        <v-btn to="login" color="primary" v-if="passwordResetAction.color === 'success'">Proceed to Login</v-btn>
                    </v-card-text>
                    <v-card-text v-else>
                        <p>Enter your new password below and enter it a second time to confirm it.</p>

                        <v-form v-model="changePasswordValid" ref="changePassword" @submit.prevent="changePassword">
                            <v-text-field
                                v-model="newPassword"
                                label="New Password"
                                type="password"
                                :rules="passwordNewRules"
                                :counter="128"
                                outlined
                            ></v-text-field>

                            <v-text-field
                                v-model="newPasswordConfirm"
                                label="Confirm Password"
                                type="password"
                                :rules="passwordNewRules.concat(passwordConfirmationRule)"
                                :counter="128"
                                outlined
                            ></v-text-field>

                            <v-btn color="rfaccent2" dark @click="changePassword" :loading="loading">
                                Reset Password
                            </v-btn>
                        </v-form>
                    </v-card-text>
                </v-card>

                <v-card v-else class="elevation-10" :xcolor="passwordResetRequest.color">
                    <v-card-title class="headline">Reset Password</v-card-title>
                    <v-card-text v-if="passwordResetRequest.state">
                        {{ passwordResetRequest.message }}
                    </v-card-text>
                    <v-card-text v-else>
                        <p>Forgot your password or having trouble logging in? You can reset your password by having
                            an email sent to your registered email address. Click on the link or copy it in your
                            browser's address bar and you can create a new password.</p>

                        <p>Don't have access to your email address anymore? Please
                            <router-link to="/contact">contact us</router-link>
                            for assistance.
                        </p>

                        <v-form v-model="resetPasswordValid" ref="resetPassword" @submit.prevent="passwordReset">
                            <v-text-field
                                v-model="identity"
                                :rules="identityRules"
                                label="Username, Email, or GMRS Callsign"
                                autocomplete="off"
                                outlined
                            ></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions v-if="!passwordResetRequest.state">
                        <v-spacer></v-spacer>
                        <v-btn color="rfaccent2" dark @click="passwordReset" :loading="loading">Reset Password</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <v-snackbar
            v-model="snackbar.state"
            :color="snackbar.color"
            :timeout="snackbar.timeout"
            multi-line
            top
        >
            <v-icon v-if="snackbar.icon" class="mr-1">{{ snackbar.icon }}</v-icon>
            {{ snackbar.text }}
        </v-snackbar>

    </v-container>
</template>

<script>
    import config from '../config';
    import axios from 'axios';
    import AdBTWR from '@/components/ads/BTWR.vue';
    import { useTitle } from '@vueuse/core';

    export default {
        name: 'ResetPassword',

        props: ['user'],

        components: {AdBTWR},

        data() {
            return {
                changePasswordValid: false,
                resetPasswordValid: false,
                loading: false,
                identity: null,
                resetToken: null,
                newPassword: null,
                newPasswordConfirm: null,
                passwordResetRequest: {
                    state: false,
                    color: '',
                    message: null,
                },
                passwordResetAction: {
                    state: false,
                    color: '',
                    message: null,
                },
                passwordNewRules: [
                    v => !!v || 'New Password is required',
                    v => (v && v.length >= 8) || 'Must be at least 8 characters',
                    v => (v && v.length <= 64) || 'Must be no greater than 64 characters'
                ],
                identityRules: [
                    v => !!v || 'Field is required',
                ],
                showLogin: false,
                snackbar: {
                    state: false,
                    color: null,
                    timeout: 5000,
                    text: null
                }
            }
        },

        computed: {
            passwordConfirmationRule() {
                return () => (this.newPassword === this.newPasswordConfirm) || 'Password must match'
            }
        },

        methods: {
            passwordReset() {
                if (!this.$refs.resetPassword.validate()) return;

                this.loading = true;

                axios
                    .post(config.API_LOCATION + '/reset-password', {identity: this.identity})
                    .then(response => {
                        if (response.data.success) {
                            // this.snackbar.state = false;
                            // this.snackbar.color = 'success';
                            // this.snackbar.icon = 'mdi-check';
                            // this.snackbar.text = 'A password reset email has been sent to the address on file for ' + response.data.username + '.';
                            // this.snackbar.state = true;

                            this.passwordResetRequest.color = 'success';
                            this.passwordResetRequest.icon = 'mdi-check';
                            this.passwordResetRequest.message = 'A password reset email has been sent to the address on file for ' + response.data.username + '. Please follow the instructions in the email to select a new password.';
                            this.passwordResetRequest.state = true;

                        } else {
                            // this.snackbar.state = false;
                            // this.snackbar.color = 'error';
                            // this.snackbar.icon = 'mdi-alert';
                            // this.snackbar.text = 'An error occurred while attempting to send a password reset email: ' + response.data.message;
                            // this.snackbar.state = true;

                            this.passwordResetRequest.color = 'error';
                            this.passwordResetRequest.icon = 'mdi-alert';
                            this.passwordResetRequest.message = 'An error occurred while attempting to send a password reset email: ' + response.data.message;
                            this.passwordResetRequest.state = true;
                        }
                    })
                    .catch(err => {
                        // this.snackbar.state = false;
                        // this.snackbar.color = 'error';
                        // this.snackbar.icon = 'mdi-alert';
                        // this.snackbar.text = 'An error occurred while attempting to send a password reset email: ' + (err.response.data.message || err.message);
                        // this.snackbar.state = true;

                        this.passwordResetRequest.color = 'error';
                        this.passwordResetRequest.icon = 'mdi-alert';
                        this.passwordResetRequest.message = 'An error occurred while attempting to send a password reset email: ' + (err.response.data.message || err.message);
                        this.passwordResetRequest.state = true;
                    })
                    .then(() => {
                        this.loading = false;
                        this.identity = null;
                    });
            },

            changePassword() {
                if (!this.$refs.changePassword.validate()) return;

                this.loading = true;

                //send token along to server for secondary validation
                axios
                    .post(config.API_LOCATION + '/reset-password', {
                        token: this.resetToken,
                        newPassword: this.newPassword
                    })
                    .then(response => {
                        if (response.data.success) {
                            // this.snackbar.state = false;
                            // this.snackbar.color = 'success';
                            // this.snackbar.icon = 'mdi-check';
                            // this.snackbar.text = 'Your password has been reset successfully for the username ' + response.data.username + '.';
                            // this.snackbar.state = true;

                            this.passwordResetAction.color = 'success';
                            this.passwordResetAction.icon = 'mdi-check';
                            this.passwordResetAction.message = 'Your password has been reset successfully for the username ' + response.data.username + '.';
                            this.passwordResetAction.state = true;

                            // this.$router.push({name: 'login'});
                        } else {
                            // this.snackbar.state = false;
                            // this.snackbar.color = 'error';
                            // this.snackbar.icon = 'mdi-alert';
                            // this.snackbar.text = 'An error occurred while attempting to set your new password: ' + response.data.message;
                            // this.snackbar.state = true;

                            this.passwordResetAction.color = 'error';
                            this.passwordResetAction.icon = 'mdi-alert';
                            this.passwordResetAction.message = 'An error occurred while attempting to set your new password: ' + response.data.message;
                            this.passwordResetAction.state = true;
                        }
                    })
                    .catch(err => {
                        // this.snackbar.state = false;
                        // this.snackbar.color = 'error';
                        // this.snackbar.icon = 'mdi-alert';
                        // this.snackbar.text = 'An error occurred while attempting to set your new password: ' + (err.response.data.message || err.message);
                        // this.snackbar.state = true;

                        this.passwordResetAction.color = 'error';
                        this.passwordResetAction.icon = 'mdi-alert';
                        this.passwordResetAction.message = 'An error occurred while attempting to set your new password: ' + (err.response.data.message || err.message);
                        this.passwordResetAction.state = true;
                    })
                    .then(() => {
                        this.loading = false;
                    });
            }
        },

        mounted() {
            useTitle('Reset Password - myGMRS.com');

            if (this.$route.query.token) {
                this.resetToken = this.$route.query.token;
                this.$router.push({query: {}})
            }
        }
    }
</script>
